<template>
  <div>
    <v-card
      v-for="election in elections"
      :key="election.id"
      class="mb-5"
      color="grey lighten-5"
      @click="edit(election.id)"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <h1>{{ election.name }}</h1>
          </v-list-item-title>
          <v-list-item-subtitle>
            <h2>
              {{ election.organization.name }} -
              {{ election.organization.description }}
            </h2>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-icon color="red" large>mdi-circle-edit-outline</v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import elections from "@/services/elections";

export default {
  name: "ElectionForm",

  data: () => ({
    elections: []
  }),
  mounted() {
    let self = this;
    elections.get().then(response => {
      self.elections = response.data;
    });
  },
  methods: {
    notify(election) {
      elections.notify(election).then(() => {
        alert("Encolado");
      });
    },
    onResults(election) {
      this.$router.push("/elections/" + election.id + "/results");
    },
    edit(election) {
      this.$router.push("/wizard/" + election + "/edit");
    }
  }
};
</script>
