<template>
  <div class="dashboard">
    <v-card class="mx-auto mt-8" flat>
      <v-card-title>
        <h2 class="red--text">Elecciones</h2>
        <v-spacer></v-spacer>
        <router-link
          :to="'/wizard/new'"
          style="text-decoration: none; color: inherit;"
        >
          <v-btn color="red" dark>
            <v-icon left>mdi-plus</v-icon>nueva elección
          </v-btn>
        </router-link>
      </v-card-title>
      <v-card-text class="pb-8">
        <listElections></listElections>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import listElections from "@/components/elections/List.vue";
export default {
  name: "Dashboard",
  components: {
    listElections
  }
};
</script>
